import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Features from '@solid-ui-blocks/Features/Block06'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Testimonials from '../blocks/Quotes/Block03'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Faq from '@solid-ui-blocks/Faq/Block02'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalSimple content={content['privacy-policy']} />
      <ModalSimple content={content['disclaimer']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='5' />
      <Hero content={content['hero']} reverse />
      <Divider space='6' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <Divider space={-6} />
        <Divider space='4' />
        <Features content={content['features']} />
      </Container>
      <Divider space='5' />
      <Companies content={content['companies']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
          <FeatureTabOne content={content['feature-tab-one']} />
          <FeatureTabTwo content={content['feature-tab-two']} />
          <FeatureTabThree content={content['feature-tab-three']} />
      </Container>
      <Divider space='5' />
      <Faq content={content['faq']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.testimonialsContainer}>
        <Testimonials content={content['testimonials']} />
      </Container>
      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query siteIndexBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "site/shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default IndexPage
